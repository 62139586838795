import React, { Suspense, lazy, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

const CalonPegawai = lazy(() => import("./components/CalonPegawai"));
const Pegawai = lazy(() => import("./components/Pegawai"));
const NotFound = lazy(() => import("./components/NotFound"));
const PanduanDrive = lazy(() =>
	import("./components/OtherComponents/PanduanDrive")
);
const RootAdmin = lazy(() => import("./components/RootAdmin"));

const App = () => {
	return (
		<Suspense
			fallback={
				<div className="ring">
					<b>Loading</b>
					<span className="loading-span"></span>
				</div>
			}
		>
			<React.Fragment>
				<Switch>
					<Route exact path="/" component={() => <Redirect to="/np" />} />
					<Route path="/np" component={() => <CalonPegawai />} />
					<Route path="/p" component={() => <Pegawai />} />
					<Route path="/panduanDrive" component={() => <PanduanDrive />} />
					<Route path="/rootAdmin" component={() => <RootAdmin />} />
					<Route component={() => <NotFound />} />
				</Switch>
			</React.Fragment>
		</Suspense>
	);
};

export default App;
